$content-max-width: 730px;

.spacer-vertical-40 {
    height: 60px;
}

body {
    margin: 0;
    font: 100%/1.625 -apple-system,'BlinkMacSystemFont','Segoe UI','Roboto','Helvetica','Arial',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: normal;
    font-stretch: normal;
    font-size: 100%;
    line-height: 1.625;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-family: -apple-system,'BlinkMacSystemFont','Segoe UI','Roboto','Helvetica','Arial',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
    background-color: #1f4373;
    
    h1, h2, h3, h4, h5 {
        font-weight: 600;
        line-height: 1.2;
    }

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    .content {
        background-color: white;

        .content-centered {
            padding-top: 60px;
            margin-left: auto;
            margin-right: auto;
            max-width: $content-max-width;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px;

            img {
                max-width: 100%;
            }
        }
    }
}

.main-nav {
    height: 102px;
    background-color: #1f4373;
    display: flex;
    align-items: start;
    justify-content: end;

    .logo {
        margin-left: 40px;

        img {
            border-radius: 10px;
        }
    }

    .links {
        display: flex;
        flex-wrap: wrap-reverse;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        padding-left: 20px;
        overflow: hidden;
        height: 100px;

        a {
            color: white;
            padding-left: 20px;
            padding-right: 20px;
            height: 100px;
            display: flex;
            align-items: center;

            @media (max-width: 760px) {
                padding-left: 13px;
                padding-right: 13px;
            }
        }
    
        .current {
            text-decoration: underline;
        }
    }

    .expand-links-container {
        height: 100%;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .expand-links {
        background: none;
        border: none;
        color: white;
        cursor: pointer;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }

    .expand-links:hover {
        background: #ffffff11;
    }
}

.sub-nav {
    overflow: hidden;

    &.opened, &.closed {
        max-height: 0px;
        transition: max-height 0.3s ease-in-out;
    }

    &.opened {
        max-height: 260px;
    }

    .links {
        display: flex;
        align-items: flex-end;
        flex-direction: column-reverse;
        padding-right: 30px;
        padding-bottom: 20px;
        font-size: 1.3rem;
    }

    a {
        color: white;
    }
}

.post-summary-content {
    max-width: $content-max-width;
    margin-left: 20px;
    margin-right: 20px;
}

.post-summary-hero {
    background-color: #2e62a6;
    color: white;
    display: flex;
    justify-content: center;

    .post-summary-content {
        flex-grow: 1;
        padding-top: 60px;
        padding-bottom: 20px;

        h2 {
            margin-top: 0px;
            margin-bottom: 0;
            a {
                font-size: 2rem;
                color: white;
            }
        }

        .post-summary-meta {
            color: #0d2340;
            font-size: .8em;
            margin-bottom: 7px;
            margin-top: 0;

            a {
                color: #FFFFFF99;
            }
        }

        .post-summary-description {
            font-size: 1;
        }

        .post-summary-player {
            background-color: #fff;
            border-radius: 5px;
            padding: 10px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                max-width: 100%;
                width: 90px;
                height: 90px;
                @media (max-width: 400px) {
                    margin-right: 10px;
                    width: 50px;
                    height: 50px;
                }
            }

            audio {
                flex-grow: 1;
                margin: 0 10px;
            }
        }

        .post-summary-show-notes {
            color: #0d2340;
        }
    }
}

.post-summary {
    display: flex;
    justify-content: center;

    .post-summary-content {
        flex-grow: 1;
        display: flex;
        border-bottom: 1px solid #ddd;
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 40px;

        h3 {
            margin-bottom: 0;
            margin-top: 0;
        }

        .post-summary-content-image {
            margin-right: 20px;
            width: 100px;
            height: 100px;

            img {
                width: 100px;
                height: 100px;
            }
        }

        a {
            color: #1f4373;
            font-size: 1.3rem;
        }

        p {
            color: #000000cc;
            margin: 0;
        }

        .post-summary-meta {
            color: #0d2340;
            font-size: .8em;
            margin-top: 0;
            margin-bottom: 5px;

            a {
                font-size: 1em;
                color: #397ed9;
            }
        }
    }

    .post-summary-content:first-of-type {
        // margin-top: 20px;
    }
}

.pagination {
    max-width: $content-max-width;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .page_number {
        padding-left: 10px;
        padding-right: 10px;
    }

    .next, .previous {
        min-width: 70px;
        padding-top: 8px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 0.7rem;
    }

    a.next, a.previous {
        background-color: #2e62a6;
        color: white;
    }

    span.next, span.previous {
        background-color: #c8daf1;
        color: #677180;
    }
}

.post-title {
    margin-top: 0;
    margin-bottom: 5px;
}

.post-meta {
    font-size: .8em;
    margin-bottom: 7px;
    margin-top: 0;
}

.post-player {
    background-color: #ddd;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
        margin-right: 20px;
        max-width: 100%;
        width: 90px;
        height: 90px;
        @media (max-width: 400px) {
            margin-right: 10px;
            width: 50px;
            height: 50px;
        }
    }

    audio {
        flex-grow: 1;
        margin: 0 10px;
    }
}

.footer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 15px 0;
    background-color: #1f4373;
    color: #FFFFFF77;

    .links {
        align-content: center;
        justify-content: center;
        display: flex;
        align-items: center;

        a {
            color: white;
            padding: 0 10px;
        }
    }

    p {
        font-size: 0.7rem;
        text-align: center;
    }
}

.page-not-found {
    text-align: center;
    padding-bottom: 20px;
}

.membership-button {
    margin-top: 20px;
    margin-bottom: 40px;

    a {
        background-color: #ff424d;
        color: #FFFFFF;
        cursor: pointer;
        padding: 8px 16px;
        border-radius: 8px;
        transition: 0.5s;
    }

    a:hover {
        text-decoration: none;
        background-color: #db262f;
    }
}

.transcript-container {
    h2 {
        margin-bottom: 6px;
    }

    h5 {
        margin-top: 0;
    }

    .time-stamp {
        font-size: 0.7em;
        opacity: 0.5;
        margin: 0;
    }

    .transcript-line {
        margin-top: 0;
        margin-bottom: 8px;
    }
}